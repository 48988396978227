ion-refresher {
    background-color: $success;
    padding: 30px 0 300px;
}
.refresher-ios .refresher-pulling-text,
.refresher-ios .refresher-refreshing-text {
    color: #ffffff;
    margin-top: -10px;
}
.refresher-ios .refresher-pulling-text {
    margin-top: -60px;
}
