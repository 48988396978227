@include media-breakpoint-down(xs) {
    .modal-signature {
        max-width: 100%;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 0px;
        min-height: 98%;
        .modal-content {
            height: 100%;
            .sig-container {
                //min-height: 250px;
            }
        }
    }
}
