@media screen {
    @include media-breakpoint-down(sm) {
        .page-report-signature {
            .col-3 {
                flex: 0 0 50%;
                max-width: 50%;
            }
            .offset-2 {
                margin-left: 0;
            }
            .col-4 {
                flex: 0 0 50%;
                max-width: 50%;
            }
            .offset-4 {
                margin-left: 25%;
            }
        }
    }
    .page-break {
        height: 2px;
        background-color: #e4e4e4;
        margin: 40px 0;
    }
}
.page-break {
    page-break-before: always;
}

@media print {

    body {
        background-color: #FFFFFF !important;
        padding: 0 !important;
        margin: 0 !important;
        .box {
            box-shadow: none !important;
            margin: 0 !important;
            padding: 0 !important;
            .page-report-table {
                width: 100%;
            }
        }
    }

    .page {
        page-break-after: always;
    }

    .no-print {
        display: none !important;
    }

    jdiv {
        display: none !important;
    }
}

@page {
    margin: 40mm 20mm 20mm;
}