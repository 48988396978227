.box-body {

    // clear original
    .ngx-pagination .current {
        padding: 0;
        background: none;
        color: inherit;
        cursor: inherit;
    }
    .ngx-pagination .pagination-previous a::before,
    .ngx-pagination .pagination-previous.disabled::before,
    .ngx-pagination .pagination-next a::after,
    .ngx-pagination .pagination-next.disabled::after {
        content: '';
        display: inherit;
        margin-right: inherit;
        margin-left: inherit;
    }
    .ngx-pagination .disabled {
        padding: 0;
        color: inherit;
        cursor: inherit;
    }
    .ngx-pagination li {
        margin-right: inherit;
    }

    // bootstrap
    .ngx-pagination {
        display: flex;
        @include list-unstyled();
        @include border-radius();
        -ms-flex-pack: center!important;
        justify-content: center!important;
    }

    .ngx-pagination li a, .ngx-pagination li > span {
        position: relative;
        display: block;
        padding: $pagination-padding-y $pagination-padding-x;
        margin-left: -$pagination-border-width;
        line-height: $pagination-line-height;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: $pagination-border-width solid $pagination-border-color;

        &:hover {
            z-index: 2;
            color: $pagination-hover-color;
            text-decoration: none;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border-color;
        }

        &:focus {
            z-index: 2;
            outline: $pagination-focus-outline;
            box-shadow: $pagination-focus-box-shadow;
        }

        // Opinionated: add "hand" cursor to non-disabled .page-link elements
        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
    }

    .ngx-pagination li {
        &:first-child {
            .page-link {
                margin-left: 0;
                @include border-left-radius($border-radius);
            }
        }
        &:last-child {
            .page-link {
                @include border-right-radius($border-radius);
            }
        }

        &.current span {
            z-index: 1;
            color: $pagination-active-color;
            background-color: $pagination-active-bg;
            border-color: $pagination-active-border-color;
        }

        &.disabled a, &.disabled span {
            color: $pagination-disabled-color;
            pointer-events: none;
            cursor: auto;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border-color;
        }
    }

    .ngx-pagination li:first-child a,
    .ngx-pagination li:first-child span {
        margin-left: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .ngx-pagination li:last-child a,
    .ngx-pagination li:last-child span {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    @include media-breakpoint-down(xs) {
        .ngx-pagination li {
            display: none;
        }
        .ngx-pagination li:first-child, .ngx-pagination li:last-child, .ngx-pagination li.current {
            display: inline-block;
        }
    }
}