/* FOR BUG: https://github.com/angular/angular-cli/issues/6007 */

// DASHBOARD
.dashboard-page {
    .clients {
        &:hover {
            text-decoration: none;
            .card {
                border-color: #3d9970;
                .card-title {
                    &.text-dark {
                        color: #777 !important;
                    }
                }
                img {
                    opacity: 0.7
                }
            }
        }
    }
    .cal-month-view {
        .cal-day-badge {
            display: none !important;
        }
        .cal-cell-top {
            min-height: 40px !important;
        }
        .cal-day-cell {
            min-height: 82px !important;
            &.cal-today {
                .cal-day-number {
                    font-size: 1.2em !important;
                    font-weight: bold !important;
                }
            }
            .cal-day-number {
                margin-top: 0px !important;
                margin-bottom: 0 !important;
            }
        }
    }
    .box-body-loading {
        padding: .75rem;
    }
}

// INTERNAL PAGES
.app-body {

    // CLIENTS
    &.p-clients {
        &.role-4 {
            .box-footer-empty {
                display: none !important;
            }
        }
    }
    .p-client-view {
        .cal-month-view {
            .cal-day-badge {
                display: none !important;
            }
            .cal-cell-top {
                min-height: 40px !important;
            }
            .cal-day-cell {
                min-height: 82px !important;
                &.cal-today {
                    .cal-day-number {
                        font-size: 1.2em !important;
                        font-weight: bold !important;
                    }
                }
                .cal-day-number {
                    margin-top: 0px !important;
                    margin-bottom: 0 !important;
                }
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            .card-columns-client {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
            }
        }
        @media (min-width: 576px) and (max-width: 767px) {
            .card-columns-client {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }
        }
    }

    // VISITS
    &.p-visits {
        &.p-edit {
            .thumbs {
                max-width: 108px;
            }
            @include media-breakpoint-down(md) {
                .thumbs {
                    max-width: 84px;
                }
            }
            @include media-breakpoint-down(sm) {
                .thumbs {
                    max-width: 60px;
                }
            }
        }
    }

    // PAYMENTS
    &.p-payments .page-header {
        display: none;
    }

    // RECOMMENDATIONS
    &.p-recommendations .page-header {
        display: none;
    }

    // NOTIFICATIONS
    &.p-notifications {
        .type-events_day h3 {
            font-size: 1.3rem;
            margin-top: 2rem;
            margin-bottom: 1rem
        }
        .type-events_day h3:first-child {
            margin-top: 0;
        }
        .type-events_day p {
            margin: 0 0 1rem 0;
            padding-left: 1.5em;
            position: relative;
        }
        .type-events_day p::before {
            content: '\2022';
            margin-right: 0.5em;
            position: absolute;
            left: 12px;
        }
    }

    &.p-sops {
        .modal {
            z-index: 3000;
        }
        .note-editable {
            table {
                &.table-bordered {
                    border: 1px solid #313335;
                    max-width: 100%;
                    table-layout: fixed;
                    th, td {
                        border: 1px solid #313335;
                        max-width: 100%;
                    }
                }
            }
            img {
                max-width: 100%;
                &.img-sop {
                    max-width: 100%;
                }
            }
        }
        .sop {
            table {
                table-layout: fixed;
                border: 1px solid #313335;
                td, th {
                    border: 1px solid #313335;
                }
            }
            img {
                &.img-sop {
                    max-width: 100%;
                }
            }
        }
    }

    // CALENDARS DAY
    &.p-calendars {
        &.p-day {
            p-calendar {
                .ui-calendar {
                    width: auto !important;
                }
            }
        }
    }

    // USERS PASSWORD
    &.p-password {
        display: block;
    }
}

// VISITS
body {
    &.send-active {
        overflow: hidden;
    }
}

// CONNECTIONS
body {

    &[page="p-connections"] {

        background-color: #eeeded !important;

        .app-body {

            &.p-connections {
                .page-header {
                    display: none !important;
                }
                .progress-bar-loading {
                    background-color: $success;
                }
                .container-page {
                    padding: 0 0 30px !important;
                }
            }
        }
    }
}

// PODCASTS
body {

    &[page^="p-podcasts"] {

        background-color: #eeeded !important;

        .app-body {

            &.p-podcasts {
                .page-header {
                    display: none !important;
                }
                .progress-bar-loading {
                    background-color: $purple;
                }
                .container-page {
                    padding: 0 0 30px !important;
                }
            }
        }
    }
}
