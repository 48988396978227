.preview-list {
    width: 400px;
    .dropdown-title {
        white-space: normal;
        width: 310px;
    }
    .dropdown-message {
        font-size: .85rem;
        white-space: normal;
    }
}

/* MAX WIDTH: MD */
@include media-breakpoint-down(md) {
    .preview-list {
        width: auto;
        .dropdown-item {
            .dropdown-title {
                width: auto;
                &.text-primary {
                    color: #ffffff !important;
                }
            }
            .text-muted {
                color: #d4d4d4 !important;
            }
            .dropdown-message {
                color: #ffffff;
            }
        }

    }
}