/* MAX WIDTH: LG */
@include media-breakpoint-down(lg) {

}

/* MAX WIDTH: MD */
@include media-breakpoint-down(md) {
    .d-desktop-only {
        display: none !important;
    }
    .btn-responsive, .btn-md-responsive, .btn-group-md-responsive > .btn {
        padding: 0.28rem 0.65rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
    html {
        body {
            .whatsapp-button {
                right: 0;
                bottom: 0;
                border-radius: 5px 0 0 0;
                width: 35px;
                height: 36px;
                box-shadow: none;
                font-size: 26px;

                i {
                    margin-top: 0;
                    bottom: 5px;
                }
            }

            .chat-container,
            .mobile-chat-container {
                bottom: 0 !important;
                right: 0 !important;
            }

            .mobile-chat-container {
                color: white;
                padding: 10px 0 0 !important;
                width: 47px !important;
                height: 40px !important;
                left: auto !important;
                border-radius: 5px !important;
                bottom: 0 !important;
                right: 0 !important;
            }
        }
    }
}

/* MAX WIDTH: SM */
@include media-breakpoint-down(sm) {
    .d-desktop-only {
        display: none !important;
    }
    .btn-responsive, .btn-sm-responsive, .btn-group-sm-responsive > .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
    .d-desktop-only {
        display: none;
    }
}

/* MAX WIDTH: XS */
@include media-breakpoint-down(xs) {

    body {

        &.app-admin {

            background-color: #ffffff !important;

            .main-app {
                // background-color: #eeeded;

                header {
                    background-color: #d1d1d1;
                }

                .card-columns .card:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &[page="p-connections"] {
            background-color: #eeeded !important;
        }

        &[page="p-podcasts"] {
            background-color: #eeeded !important;
        }
    }

    .d-desktop-only {
        display: none !important;
    }
    .btn-responsive, .btn-xs-responsive, .btn-group-xs-responsive > .btn {
        padding: 0.05rem 0.3rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
    .d-xs-none {
        display: none;
    }
    .px-xs-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .pl-xs-0 {
        padding-left: 0 !important;
    }
    .pr-xs-0 {
        padding-right: 0 !important;
    }
    .pr-xs-10p {
        padding-right: 10px;
    }
    .ml-xs-0 {
        margin-left: 0 !important;
    }
    .mr-xs-0 {
        margin-right: 0 !important;
    }
    .mx-xs-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .rounded-xs-0 {
        border-radius: 0 !important;
    }
    .border-left-xs-0 {
        border-left: 0 !important;
    }
    .border-right-xs-0 {
        border-right: 0 !important;
    }
    .border-x-0 {
        border-left: 0 !important;
        border-right: 0 !important;
    }
    .item-dot {
        margin-left: 0;
    }
    .title-separator {
        border-radius: 0 !important;
    }
    .container-page {
        padding: 0;

        > * > .row {
            margin-right: 0;
            margin-left: 0;

            > .col {
                padding: 0;
            }
        }

        .box {
            border-radius: 0 !important;

            .box-header {
                padding: 10px 10px 0;
                min-height: 3.2rem;
                border-radius: 0;

                .btn {
                    padding: 0.25rem 0.5rem;
                    font-size: 0.875rem;
                    line-height: 1.5;
                    border-radius: 0.2rem;
                }
            }

            .box-body {
                padding: 10px;
            }
        }

        .btn {
            &.dropdown-toggle {
                &::after {
                    margin-left: 0.055em;
                }
            }
        }

        .sidebar-menu {
            .list-group-item {
                &:last-child {
                    border-radius: 0;
                }
            }
        }
    }
    .p-client-view {
        .card {
            border-radius: 0;
            border-right: 0;
            border-left: 0;

            .card-header {
                padding: 10px;
            }

            .card-body {
                padding: 10px;
            }

            .card-footer {
                padding: 10px;
            }

            .list-group-item {
                padding: 10px;

                .item-dot {
                    display: none;
                }
            }
        }
    }
    .app-admin {
        jdiv.button_592, jdiv.overlay_716,
        jdiv.button_332, jdiv.overlay_c73,
        jdiv[class^='button_'], jdiv[class^='overlay_'] {
            position: fixed !important;
            margin: 0 !important;
            top: auto !important;
            left: auto !important;
            right: 0 !important;
            bottom: 0 !important;
            width: 47px !important;
            height: 39px !important;
            border-radius: 10px 0 0 0 !important;

            jdiv.messagesLabel_f90,
            jdiv.messagesLabel_8da,
            jdiv[class^='messagesLabel_'] {
                left: 24px;
                top: -3px;
                z-index: 100;
                background-color: #ffd230;
            }
        }
    }
}

/* MAX HEIGHT 600px (if mobile keyboard open) */
@media (max-height: 550px) {
    .app-admin jdiv.button_592, .app-admin jdiv.overlay_716,
    .app-admin jdiv.button_332, .app-admin jdiv.overlay_c73,
    .app-admin jdiv[class^='button_'], .app-admin jdiv[class^='overlay_'] {
        display: none;
    }
}

/* MIN WIDTH: XS */
@include media-breakpoint-up(xs) {
    .w-xs-auto {
        width: auto !important;
    }
}

/* MIN WIDTH: SM */
@include media-breakpoint-up(sm) {
    .w-sm-auto {
        width: auto !important;
    }
}

/* MIN WIDTH: MD */
@include media-breakpoint-up(md) {
    .w-md-auto {
        width: auto !important;
    }
}

/* MIN WIDTH: LG */
@include media-breakpoint-up(lg) {
    .d-desktop-none {
        display: none !important;
    }
    .pl-lg-30p {
        padding-left: 30px !important;
    }
    .w-lg-auto {
        width: auto !important;
    }
    .minw-lg-800p {
        min-width: 800px;
    }
}

/* MIN WIDTH: XL */
@include media-breakpoint-up(xl) {

}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix}-top {
            border-top: $border-width solid $border-color !important;
        }
        .border#{$infix}-right {
            border-right: $border-width solid $border-color !important;
        }
        .border#{$infix}-bottom {
            border-bottom: $border-width solid $border-color !important;
        }
        .border#{$infix}-left {
            border-left: $border-width solid $border-color !important;
        }

        .border#{$infix}-top-0 {
            border-top: 0 !important;
        }
        .border#{$infix}-right-0 {
            border-right: 0 !important;
        }
        .border#{$infix}-bottom-0 {
            border-bottom: 0 !important;
        }
        .border#{$infix}-left-0 {
            border-left: 0 !important;
        }

        .border#{$infix} {
            border-left: $border-width solid $border-color !important;
            border-right: $border-width solid $border-color !important;
        }

        .border#{$infix}-x {
            border-left: $border-width solid $border-color !important;
            border-right: $border-width solid $border-color !important;
        }

        .border#{$infix}-y {
            border-top: $border-width solid $border-color !important;
            border-bottom: $border-width solid $border-color !important;
        }
    }
}
