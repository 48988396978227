@import "@ionic/angular/css/core.css";
@import "_fix-bootstrap.scss";

html {
    position: relative;
    min-height: 100%;

    body {
        margin-bottom: 60px;
        background-color: #eeeded;
        font-size: 16px;
        min-height: 100%;
        padding-top: env(safe-area-inset-top, 0);

        .app-body {

            .container-page {
                background-color: #eeeded;
            }

            &.subscriber-active {
                .not-subscriber-active {
                    display: none !important;
                }
            }

            &:not(.subscriber-active) {
                .only-subscriber-active {
                    display: none !important;
                }

                .blurred-to-inactive {
                    filter: blur(4px);
                }
            }

            &:not(.h-first-steps) {
                .first-steps-button {
                    display: none !important;
                }
            }

            &.role-4 {
                .not-role-4 {
                    display: none !important;
                }
            }

            &:not(.role-4) {
                .only-role-4 {
                    display: none !important;
                }
            }

            &:not(.multiusers) {
                .multiusers {
                    display: none !important;
                }
            }

            &.multiusers {
                .not-multiusers {
                    display: none !important;
                }
            }
        }

        .rating {
            font-size: 0.8rem;

            p-rating {
                display: inline-block;
                vertical-align: middle;
            }

            &.p-rating-small {
                .p-rating-item .p-rating-icon.p-icon {
                    width: .9rem;
                    height: .9rem;
                }
            }
        }

        .ui-rating {
            a {
                color: #eac537;
            }
        }

        .cal-month-view {
            .cal-open-day-events {
                background-color: $secondary;
                box-shadow: none;
            }
        }

        .input-clear {
            input[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }

        .title-separator {
            background-color: #4CAF50;
            color: #ffffff;
            border-radius: .25rem;
        }

        .separator {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid $success;
        }

        .box-tips {
            .close {
                float: none;
                bottom: -10px;
                cursor: pointer;
                position: relative;
                font-size: 10px;
            }
        }

        .h-first-steps {
            .first-steps {
                display: none !important;
            }
        }

        .h-tips {
            .box-tips, app-box-tip {
                display: none !important;
            }
        }

        .h-required {
            .alert-required {
                display: none !important;
            }
        }

        .h-tooltip {
            color: $orange;
        }

        .not-replaced {
            color: $danger;
            border-bottom: 1px dotted $danger;
        }

        .card-stats {
            canvas {
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .google-visualization-tooltip {
            white-space: nowrap !important;
        }

        .card-stats-small .google-visualization-tooltip {
            top: auto !important;
            bottom: 100% !important;
            left: 50% !important;
            transform: translateX(-50%) translateY(-10px) !important;
            white-space: nowrap !important;
            max-width: none !important;
            width: auto !important;
            overflow: visible !important;
            display: inline-block !important;
            padding: 0 !important;
            text-align: center !important;
            background: rgba(255, 255, 255, 0.9) !important;
            border-radius: 5px !important;
            border: 1px solid #ddd !important;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
        }

        .md-container {
            display: inline-block;
            position: relative;
        }

        .md-modal-menu {
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            background-color: transparent;
            color: #ffffff;
            text-shadow: 0px 0px 3px #000000;
            padding: 0 5px;
        }

        .md-modal-menu:hover {
            background-color: rgba(255, 255, 255, .5);
            border-radius: .25rem;
        }

        app-box-tip {
            max-width: 400px !important;
        }

        .empty {
            color: #d0d0d0 !important;
        }

        .whatsapp-button {
            position: fixed;
            width: 60px;
            height: 60px;
            bottom: 10px;
            right: 10px;
            background-color: #25d366;
            color: #FFF;
            border-radius: 50px;
            text-align: center;
            font-size: 30px;
            box-shadow: 2px 2px 3px #999;
            z-index: 10000;

            &:hover {
                color: #ffbe0e;
            }

            i {
                margin-top: 16px;
            }
        }

        .chat-container,
        .mobile-chat-container {
            bottom: 10px !important;
            right: 10px !important;
        }

        &.hide-btn-delete {
            .show-btn-delete {
                display: none !important;
            }
        }

        .toastr-reload {
            font-size: .8rem;
            background-image: none;
            padding: 0.75rem !important;
            width: 250px !important;
        }

        &.environment-development {
            .hide-development {
                display: none !important;
            }
        }

        &:not(.environment-development) {
            .only-development {
                display: none !important;
            }
        }
    }
}

::placeholder {
    color: #d0d0d0 !important;
}

:-ms-input-placeholder {
    color: #d0d0d0 !important;
}

::-ms-input-placeholder {
    color: #d0d0d0 !important;
}

@media all and (display-mode: standalone) {
    .pwa-add-to-home, .pwa-add-to-home-click {
        display: none;
    }
}

body:not(.pwa-installable) .only-pwa-installable,
body:not(.is-pwa) .only-pwa,
body:not(.is-ios) .only-ios,
body.is-ios .hide-ios {
    display: none;
}

@import "ionic";
@import "pages-contents";
@import "utilities";
@import "crop";
@import "webcam";
@import "login";
@import "header";
@import "breadcrumb-line";
@import "loading";
@import "preview";
@import "buttons";
@import "box";
@import "tabs";
@import "sidebar";
@import "footer";
@import "primeng";
@import "form";
@import "editor";
@import "pagination";
@import "wizard";
@import "masonry";
@import "drag-drop";
@import "pages";
@import "subscriber";
@import "reports";
@import "responsive";
@import "signature";
@import "print";
@import "blackfriday";
@import "politics";
@import "app";
