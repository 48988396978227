.app-body {
    .alert-top {
        margin: 0;
        border-radius: 0;
        border: none;
    }

    .alert-subscriber-payment_method_change,
    .alert-subscriber-cancelled {
        display: none;
    }

    .subscriber-resources {
        display: none;
        color: $yellow;
        font-size: 0.7em;
    }

    &.subscriber-cancelled {
        .alert-subscriber-cancelled {
            display: block;
        }
    }

    &.subscriber-payment_method_change {
        .alert-subscriber-payment_method_change {
            display: block;
        }
    }

    &:not(.subscriber-active) {
        .subscriber-resources {
            display: inline-block;
        }
    }

    &.plan-anual {
        .hide-plan-anual {
            display: none;
        }
    }
}
