.main-app {

    &.is-app {

        header {
            nav {
                // display: none !important;
            }
        }

        .app-body {

            &.subscriber-inactive {

                .hide-app-inactive {
                    // display: none !important;
                }
            }
        }

        .only-not-app {
            display: none !important;
        }
    }

    &.ios {

        .hide-only-ios {
            display: none !important;
        }
    }

    &:not(.is-app) {

        .only-is-app {
            display: none !important;
        }
    }
}

.fancybox-active {
    height: 100% !important;
}

.plugin-container .fb-comments iframe {
    visibility: visible;
    background-color: #ffffff;
    width: 100% !important;
}

.plugin-container .fb-comments iframe:not([src='']) + .loading-overlay {
    visibility: hidden;
    background-color: transparent;
    opacity: 0;
}

.p-overlay-mask, .modal-backdrop {
    display: none;
}
