webcam {
  display: inline-block;
}

video.mirrored, .webcam-wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
  margin-bottom: -6px;
}
