@use "sass:math";

@function abs($number) {
    @return math.abs($number);
}

@import "custom-bootstrap";

:root {
    --ion-background-color: #eeeded;
}

@media screen and (min-width: 1025px) {
    :root {
        --ion-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    }
}
