.progress-bar-loading {
    height: 5px;
    background-color: #eeeded;
    .progress {
        height: 5px;
        border-radius: 0;
        .progress-bar {
            transform: rotate(180deg);
            &.progress-bar-animated {
                animation: progress-bar-stripes .3s linear infinite;
            }
        }
    }
}

.container-fluid {
    min-height: 100%;
    .loader-spinner {
        display: none;
    }
    .loader-spinner {
        display: none;
    }
    .loader-backdrop {
        display: none;
    }
    &.container-loader {
        position: relative;

        .loader-backdrop {
            background-color: rgba(0, 0, 0, 0.2);
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 5000;
            left: 0px;
            right: 0;
            bottom: -30px;
        }

        .loader-spinner {
            position: fixed;
            display: block;
            top: 50%;
            left: 50%;
            color: #ffffff;
            z-index: 100000;
            font-size: 50px;
            margin-left: -25px;
            text-shadow: #000000 0px 0px 1px;
        }
    }
}
