.navbar.navbar-expand-lg {
    .navbar-brand {
        width: 200px;
    }

    .navbar-nav {
        li.nav-item {
            border-right: none;
            border-bottom: 1px solid rgba(255, 255, 255, .21);

            &:last-child {
                border-right: none;
            }

            .nav-link {
                color: rgba(255, 255, 255, .8);

                &:hover {
                    background-color: rgba(255, 255, 255, .21);
                }
            }

            &.active {
                background-color: rgba(255, 255, 255, .11);
            }
        }
    }

    .navbar-nav.navbar-user {
        min-width: 100px;

        .dropdown-menu {
            min-width: 220px;
        }

        img {
            background-color: rgba(255, 255, 255, .21);
            border: 1px solid rgba(255, 255, 255, .21);
            height: 28px;
        }

        .fa {
            width: 16px;
            text-align: center;
        }
    }
}


.nav-item-sync {
    border-right: 0 !important;
}

/* MAX WIDTH: MD */
@include media-breakpoint-down(md) {
    .navbar.navbar-expand-lg {
        .navbar-nav {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                padding-top: 0;
                margin-top: 0;

                .dropdown-item {
                    border-bottom: 1px solid rgba(255, 255, 255, .21);
                    color: rgba(255, 255, 255, 0.5);

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, .21);
                    }

                    &.active {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    .nav-item-notifications {
        .dropdown-item {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .navbar-toggler {
        .badge-notifications {
            position: absolute;
            top: 5px;
            right: 9px;
            font-size: 60%;
        }
        .badge-syncing {
            position: absolute;
            bottom: 8px;
            right: 9px;
            font-size: 40%;
        }
    }
}

/* MIN WIDTH: LG */
@include media-breakpoint-up(lg) {
    .navbar.navbar-expand-lg {
        .navbar-nav {
            li.nav-item {
                border-right: 1px solid rgba(255, 255, 255, .21);
                border-bottom: none;
            }

            &.navbar-user {
                li.nav-item {
                    .nav-link {
                        padding-right: 16px;
                        padding-left: 16px;

                        .badge-wrapper {
                            position: relative;
                            display: inline-block;

                            .badge {
                                position: absolute;
                                top: -10px;
                                right: -5px;
                                padding: 2px 4px 2px 2px;
                            }
                        }

                        &#notificationDropdown {
                            padding-top: 12px;
                        }

                        &#noticesDropdown {
                            padding-top: 12px;
                        }
                    }
                }
            }
        }
    }
}

.badge-pulse {
    animation: pulse 2s ease-out;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    40% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.3, 1.3, 1.3);
    }
    55% {
        transform: scale3d(1, 1, 1);
    }
    60% {
        transform: scale3d(1.3, 1.3, 1.3);
    }
    65% {
        transform: scale3d(1, 1, 1);
    }
}
