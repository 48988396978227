body.p-login {
    --ion-background-color: #eeeded;
    background-color: #eeeded;
}

.card-login {
    .logo {
        padding: 30px 0 40px;
    }
    .divider {
        height: 0;
        margin: .5rem 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
        position: inherit;
        top: inherit;
        width: inherit;
    }
    .login-social {
        padding: 20px 0;
    }
    .login-or {
        padding: 20px 0 0;
        text-align: center;
        display: block;
        position: relative;
        .or {
            color: $gray-300;
            text-align: center;
            font-family: "Times New Roman", Times, serif;
            span {
                background: #FFFFFF;
                position: relative;
                z-index: 2000;
                padding: 0 10px;
            }
        }
        .divider {
            position: relative;
            top: -12px;
            width: 100%;
            z-index: 1000;
            margin: 0;
        }
    }
    .login-subtitle {
        &.forgot {
            padding-top: 33px;
        }
    }
}
@media (max-width: 375px) {
    re-captcha iframe {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}
@media (max-width: 359px) {
    re-captcha iframe {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
    }
}
@media (max-width: 345px) {
    re-captcha iframe {
        transform: scale(0.85);
        -webkit-transform: scale(0.85);
    }
}
@media (max-width: 329px) {
    re-captcha iframe {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
    }
}
