.box {
    background: #FFFFFF;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
    border-radius: .25rem !important;
    &.box-gray-light {
        background-color: $gray-light;
    }
    .box-header {
        padding: 15px;
        border-radius: 0.25rem 0.25rem 0 0;
        background-color: rgba(0, 0, 0, 0.01);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        min-height: 3.9rem;
        .box-header-title {
            padding-top: 2px;
        }
        .box-header-filter {
            @include media-breakpoint-up(md) {
                margin-top: -3px;
                margin-bottom: -4px;
                .input-group-append {
                    &.has-search {
                        &:not(.has-items) {
                            display: none;
                        }
                    }
                }
            }
            .btn-actions {
                @include media-breakpoint-down(xs) {
                    padding: 0.375rem 0.75rem 0.175rem 0.5rem;
                }
            }
            .dropdown-menu {
                padding: 0;
                border-radius: 0.25rem;
                .dropdown-item {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 0.75rem 1.25rem;
                    &:last-child {
                        border-bottom: none;
                    }
                    .fa {
                        width: 20px;
                    }
                }
                .dropdown-divider {
                    border-top: 1px solid rgba(0, 0, 0, 0.15);
                }
                .dropdown-item-search {
                    padding: 0.5rem;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
    .box-body {
        padding: 15px;
        position: relative;
        .box-loading-icon {
            position: absolute;
            right: 5px;
            top: 5px;
        }
        .box-saved-icon {
            position: absolute;
            right: 5px;
            top: 0px;
            opacity: .6;
        }
    }
    .box-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 15px;
    }
}



.app-body {
    .box-header-filter {
        .dropdown-menu {
            .dropdown-item {
                border-bottom: 0;
            }
        }
        .filter-principal-search {
            margin-left: .25rem;
        }
    }
    &.w-md, &.w-lg, &.w-xl {
        .box-header-filter {
            &:not(.box-header-filter-has-items) {
                .filter-items-group {
                    display: none;
                }
                .filter-principal-search {
                    input {
                        border-radius: 0.25rem;
                    }
                }
            }
            &.box-header-filter-items-1 {
                .filter-items-group {
                    display: none;
                }
                .filter-principal-search {
                    input {
                        border-radius: 0.25rem;
                    }
                }
            }
            &.box-header-filter-items-2 {
                &:not(.box-header-filter-has-search) {
                    .filter-items-group {
                        display: none;
                    }
                }
                &.box-header-filter-has-search {
                    .filter-items {
                        display: none;
                    }
                    &:not(.box-header-filter-has-buttons) {
                        .btn-actions {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                    .dropdown-item-search {
                        display: none;
                    }
                    &.box-header-filter-has-buttons {
                        .filter-items-group {
                            margin-left: .25rem;
                        }
                        .filter-principal-search {
                            input {
                                border-radius: 0.25rem;
                            }
                        }
                    }
                }
            }
            &.box-header-filter-items-big {
                .filter-items {
                    display: none;
                }
                .dropdown-item-search {
                    display: none;
                }
                &.box-header-filter-has-search {
                    &:not(.box-header-filter-has-buttons) {
                        .btn-actions {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                    &.box-header-filter-has-buttons {
                        .filter-principal-search {
                            input {
                                border-radius: 0.25rem;
                            }
                        }
                    }
                }
                &.box-header-filter-has-buttons {
                    .filter-items-group {
                        margin-left: .25rem;
                    }
                }
            }
        }
    }
    &.w-xs, &.w-sm {
        .box-header-filter {
            .filter-principal-search {
                display: none;
            }
            .filter-items-group {
                 margin-left: .25rem;
            }
            &.box-header-filter-items-1 {
                &:not(.box-header-filter-has-search) {
                    .filter-items-group {
                        display: none;
                    }
                }
                &.box-header-filter-has-search {
                    .filter-items {
                        display: none;
                    }
                }
            }
            &.box-header-filter-items-2 {
                .filter-items {
                    display: none;
                }
            }
            &.box-header-filter-items-big {
                .filter-items {
                    display: none;
                }
            }
        }
    }
}

.box .box-header .box-header-filter .dropdown-menu .dropdown-item {
    border-bottom: 0;
}

.card.card-hover:hover {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.03);
    border-color: rgba(0, 0, 0, 0.255)
}

.box-error {
    border-color: red;
    .box-header, .card-header {
        color: red;
    }
}

@include media-breakpoint-down(xs) {
    .card-mobile-full {
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
    }
}

.expandable-text-fade {
    display: none;
}
.expandable-text {
    overflow: hidden;
    color: #00626e;
    max-height: 7em;
    position: relative;
    cursor: pointer;
    .expandable-text-fade {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(transparent, #ffffff);
        text-align: center;
        display: grid;
        i {
            margin-top: auto;
            opacity: .7;
        }
    }
}

.expandable-text-green {
    background-color: #defddf;
    border-color: #c5edc7;
    .expandable-text-fade {
        background: linear-gradient(transparent, #defddf);
    }
}
