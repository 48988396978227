.fs-1 {
    font-size: 0.25rem !important;
}

.fs-2 {
    font-size: 0.5rem !important;
}

.fs-3 {
    font-size: 1rem !important;
}

.fs-4 {
    font-size: 1.5rem !important;
}

.fs-5 {
    font-size: 3rem !important;
}

.fs-10p {
    font-size: 10px !important;
}

.fs-11p {
    font-size: 11px !important;
}

.fs-12p {
    font-size: 12px !important;
}

.fs-13p {
    font-size: 13px !important;
}

.fs-14p {
    font-size: 14px !important;
}

.fs-15p {
    font-size: 15px !important;
}

.fs-16p {
    font-size: 16px !important;
}

.fs-18p {
    font-size: 18px !important;
}

.fs-22p {
    font-size: 22px !important;
}

.fs-26p {
    font-size: 26px !important;
}

.w-15p {
    width: 15px;
}

.mw-50p {
    max-width: 50px;
}

.mw-100p {
    max-width: 100px;
}

.mw-150p {
    max-width: 150px;
}

.mw-200p {
    max-width: 200px;
}

.mw-250p {
    max-width: 250px;
}

.mw-400p {
    max-width: 400px;
}

.mw-500p {
    max-width: 500px;
}

.mw-600p {
    max-width: 600px;
}

.mw-700p {
    max-width: 700px;
}

.mw-800p {
    max-width: 800px;
}

.mw-900p {
    max-width: 900px;
}

.mw-1000p {
    max-width: 1000px;
}

.mw-1200p {
    max-width: 1262px;
}

.minw-100p {
    min-width: 100px;
}

.pt-2p {
    padding-top: 2px !important;
}

.pl-8 {
    padding-left: 2.5rem !important;
}

.mh-100p {
    max-height: 100px !important;
}
.mh-300p {
    max-height: 300px !important;
}

.no-shadow {
    box-shadow: none !important;
    text-shadow: none !important;
}

.no-underline {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.thumb-img {
    line-height: 40px;
    width: 40px;
    text-align: center;
    font-size: 17px;
    border-radius: 50px;
    float: left;
}

.border-black {
    border-color: #000000;
}

.break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.fa-layers {
    display: inline-block;
    height: 4em;
    position: relative;
    vertical-align: -.125em;
    width: 4em;
}

.fa-layers .fa {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: auto;
}

.lh-1 {
    line-height: 1;
}

.ul-thin {
    list-style-type: none;
    padding-left: 8px;
    text-align: left;
    position: relative;

    li:before {
        content: "•";
        position: absolute;
        left: 0;
        color: $success;
    }
}

.item-dot {
    font-size: 0.25rem !important;
    margin-left: -8px;
    vertical-align: middle !important;
    color: $primary !important;

    &.item-dot-black {
        color: #000000 !important;
    }
}

.strike, .through, .text-strike, .text-through {
    text-decoration: line-through;
    text-decoration-color: #a5a5a5;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.link-break, .span-break-word span {
    word-wrap: break-word;
    display: inline-block;
    max-width: 250px;
}
