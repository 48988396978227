.wizard {
    margin-bottom: 1rem;
    .steps {

        .step {
            padding-right: 5px;
            padding-left: 5px;
            a {
                color: $gray-900;
                .content {
                    background-color: $gray-super-light;
                    border-radius: 0.25rem;
                    text-align: center;
                    padding: 0.75rem;
                    .icon {
                        font-size: 26px;
                        line-height: 1;
                    }
                    .title {
                        font-size: 1rem;
                    }
                    .description {
                        font-size: 0.75rem;
                    }
                    .check {
                        color: $success;
                        font-size: 18px;
                        position: absolute;
                        border-radius: 10px;
                        top: 2px;
                        left: 8px;
                        background-color: #ffffff;
                        height: 19px;
                        border: 1px solid #ffffff;
                        width: 19px;
                    }
                }
                &:hover {
                    text-decoration: none;
                    .content {
                        opacity: 0.8;
                    }
                }
            }
            &.current {
                a {
                    color: #ffffff;
                    .content {
                        background-color: $primary;
                    }
                }
            }
            &:first-child {
                padding-left: 15px;
                a {
                    .content {
                        .check {
                            left: 18px;
                        }
                    }
                }
            }
            &:last-child {
                padding-right: 15px;
            }
        }
    }
}






/*
.wizard {
    counter-reset: stepNo;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: 0;
    padding: 0;
}

.wizard-step {
    -webkit-flex: 1 0 0px;
    -ms-flex: 1 0 0px;
    flex: 1 0 0px;
    list-style: none;
    padding: 0 2% 0 0;
    position: relative;
}
.wizard-step::before {
    border-top: 3px solid #333;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 25px;
}
.wizard-step:last-child {
    border: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
}
.wizard-step:last-child::before {
    border: 0 none;
}
.wizard-step:last-child:nth-child(2) {
    max-width: calc(100% / 2);
}
.wizard-step:last-child:nth-child(3) {
    max-width: calc(100% / 3);
}
.wizard-step:last-child:nth-child(4) {
    max-width: calc(100% / 4);
}
.wizard-step:last-child:nth-child(5) {
    max-width: calc(100% / 5);
}
.wizard-step:last-child:nth-child(6) {
    max-width: calc(100% / 6);
}
.wizard-step:last-child:nth-child(7) {
    max-width: calc(100% / 7);
}
.wizard-step:last-child:nth-child(8) {
    max-width: calc(100% / 8);
}
.wizard-step:last-child:nth-child(9) {
    max-width: calc(100% / 9);
}
.wizard-step:last-child:nth-child(10) {
    max-width: calc(100% / 10);
}

.wizard-step-link {
    color: #333;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    text-decoration: none;
    z-index: 10;
}
.wizard-step-link:before {
    background: #333;
    color: #fff;
    counter-increment: stepNo;
    content: counter(stepNo) ".";
    display: block;
    font-size: 2rem;
    height: 50px;
    line-height: 50px;
    margin-bottom: 12px;
    text-align: center;
    width: 50px;
}
.wizard-step-link:hover,
.wizard-step-link:focus {
    color: #000;
    text-decoration: none;
}
.wizard-step-link:hover:before,
.wizard-step-link:focus:before {
    background: #fff;
    border: 1px solid #333;
    color: #333;
}
*/