.termos-de-uso ol:not([type="a"]) {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

.termos-de-uso ol:not([type="a"]) > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    font-weight: bold;
    padding-left: 8px;
    padding-top: 6px;
    text-align: justify;
    text-transform: uppercase;
}

.termos-de-uso ol:not([type="a"]) > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    font-weight: bold;
}

.termos-de-uso ol:not([type="a"]) > li ol:not([type="a"]) > li {
    margin: 0;
    font-weight: normal;
    background: none;
    padding-left: 0;
    padding-right: 8px;
    text-transform: none;
}

.termos-de-uso ol:not([type="a"]) > li > ol:not([type="a"]) > li:before {
    content: counters(item, ".") " ";
    font-weight: normal;
}

.termos-de-uso ol[type="a"] li {
    padding-top: 6px;
}

.termos-de-uso ol[type="a"] li:last-child {
    padding-bottom: 6px;
}

.politicas-de-pagamentos ol.index {
    display: none;
}

.politicas-de-pagamentos .glossary {
    list-style-type: none;
    padding: 0;
}

.politicas-de-pagamentos h2 {
    font-size: 1.6rem;
    font-weight: bold;
}

.politicas-de-pagamentos .clausules li p:last-child,
.politicas-de-pagamentos ol.clausules li ul {
    margin-bottom: 40px;
}

.politicas-de-pagamentos ol:last-child {
    margin-bottom: inherit;
    padding-left: 26px;
}

.politicas-de-pagamentos ol:last-child li::marker {
    font-weight: bold;
    font-size: 1.5rem;
}

.politicas-de-pagamentos .glossary {
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 20px !important;
    background-color: #f9f9f9;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.politicas-de-pagamentos li p:last-child {
    margin-bottom: 0;
}

.politicas-de-privacidade table {
    border-collapse: collapse;
}

.politicas-de-privacidade table tr th {
    border: 1px solid #999999;
}

.politicas-de-privacidade li {
    margin-bottom: 1.5rem;
}

.politicas-de-privacidade li::marker {
    font-weight: bold;
}

.politicas-de-privacidade th, .politicas-de-privacidade td {
    padding: 10px 20px;
}

.politicas-de-privacidade th {
    text-align: center;
}

.politicas-de-privacidade table {
    margin-bottom: 2rem;
}

.politicas-de-privacidade td:first-child {
    text-align: left;
}

.politicas-de-privacidade .adreess {
    max-width: 420px;
    background-color: #fffbd3;
    padding: 20px;
    border-radius: 5px;
    margin: 0 auto;
    text-align: left;
}

.politicas-de-privacidade .adreess p:last-child {
    margin-bottom: 0;
}
