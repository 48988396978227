.show-blackfriday, .show-blackfriday-month {
    display: none;
}

.subscriber-active-by-blackfriday { // sistema liberado para blackfriday
    .show-blackfriday {
        display: block;
    }

    .hide-blackfriday {
        display: none !important;
    }

    .blackfriday-register-img {
        margin-top: -20px;
    }

    .blackfriday-alert-top {
        background-color: #000;
        color: #fff;
    }

    .blackfriday-alert-top .close {
        color: #fff;
    }

    .plan-mes {
        .show-blackfriday-mes {
            display: inline-block !important;
        }
    }

    .plan-anual {
        .show-blackfriday-anual {
            display: inline-block !important;
        }
    }
}

.role-3.subscriber-active-by-blackfriday-month { // css para mensalistas ativos na blackfriday
    .show-blackfriday-month {
        display: block;
    }

    .hide-blackfriday-month {
        display: none !important;
    }

    .blackfriday-month-alert-top {
        background-color: #000;
        color: #fff;
    }

    .blackfriday-month-alert-top .close {
        color: #fff;
    }

    .plan-mes {
        .show-blackfriday-month-mes {
            display: inline-block !important;
        }
    }

    .plan-anual {
        .show-blackfriday-month-anual {
            display: inline-block !important;
        }
    }
}
