#sidebar {
    .box {
        .box-body {
            padding: 0px;
            .list-group {
                .list-group-item {
                    padding: 0;
                    .list-group-item-action {
                        padding: 0.75rem 1.25rem;
                        display: block;
                    }
                    &:last-child {
                        border-radius: 0 0 0.25rem 0.25rem;
                    }
                    &.active {
                        background-color: rgba(0, 0, 0, 0.04);
                        border-color: rgba(0, 0, 0, 0);
                    }
                }
                &.list-group-flush {
                    .list-group-item {
                        border-left: 2px solid rgba(0, 0, 0, 0.3);
                        &:hover {
                            border-left-color: $warning;
                        }
                        &.active {
                            border-left-color: $primary;
                        }
                    }
                }
            }
        }
    }
}

.list-group-flush .list-group-item {
    border-left: 2px solid #FFFFFF;
}
