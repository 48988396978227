:root {
    --p-border-radius-xs: 0.25rem !important;
    --p-border-radius-sm: 0.25rem !important;
    --p-border-radius-md: 0.25rem !important;
    --p-border-radius-lg: 0.25rem !important;
    --p-border-radius-xl: 0.25rem !important;

    --p-form-field-padding-x: 0.75rem !important;
    --p-form-field-padding-y: 0.375rem !important;

    --p-emerald-500: #9acffa !important;
    --p-primary-500: #9acffa !important;




}
