label {
    &.required:after {
        content: " *";
        color: $danger;
        font-size: 14px;
    }
    i {
        color: $orange;
    }
}
i.help {
    color: $orange;
    vertical-align: bottom;
}
.btn-submit {
    .btn {
        position: relative;
        .btn-submit-dirty {
            position: absolute;
            top: -1px;
            right: 3px;
            color: yellow;
            font-size: 14px;
        }
        .btn-submit-error {
            position: absolute;
            top: -1px;
            right: 3px;
            color: #a50b00;
            font-size: 14px;
        }
    }
}
form:not(.ng-untouched) p-calendar.ng-invalid .p-inputtext,
form:not(.ng-untouched) p-dropdown.ng-invalid .p-dropdown,
form:not(.ng-untouched) .border-invalid,
form:not(.ng-untouched) .ng-invalid,
.ng-invalid:not(.ng-untouched) .p-autocomplete .p-inputtext,
.ng-invalid:not(.ng-untouched) .p-autocomplete .p-autocomplete-dropdown,
.ng-invalid:not(.ng-untouched) {
    border-color: $danger !important;
}
form:not(.ng-untouched) .border-bottom-invalid {
    border-bottom: 1px solid $danger;
    padding-bottom: 8px;
    margin-bottom: -9px;
}
form:not(.ng-untouched) .border-left-invalid {
    border-left: 1px solid $danger;
    margin-left: -5px;
    padding-left: 4px;
}
.p-calendar {
    width: 100%;
    display: inline-flex;
    .p-inputtext {
        padding: 0.375rem 0.75rem !important;
        font-size: 1rem !important;
        font-family: inherit !important;
        font-weight: 400 !important;
        color: #495057 !important;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
        &.border-danger {
            border-color: $danger !important;
        }
    }
    &.p-calendar-disabled {
        background-color: #e9ecef !important;
        opacity: 1 !important;
    }
    //.ui-datepicker-title {
    //    select {
    //        font-size: 14px;
    //        border: none;
    //        background-color: transparent;
    //        margin-bottom: -8px !important;
    //        color: #ffffff;
    //        -webkit-appearance: none;
    //        -moz-appearance: none;
    //        max-height: 200px;
    //        padding: 0 5px;
    //        margin-top: -10px !important;
    //        padding-right: 20px;
    //        text-align: center;
    //        border-bottom: 1px dashed #ffffff;
    //        option {
    //            color: #333333;
    //        }
    //    }
    //}
}
p-multiselect {
    width: 100%;
}
.p-multiselect {
    width: 100%;
    display: inline-flex;
    padding: 0.1rem 0.3rem;
    height: calc(2.25rem + 2px);
    border: 1px solid #ced4da !important;
}

.p-dropdown {
    border: 1px solid #ced4da !important;
    max-width: 100%;
    width: 100%;
    .p-dropdown-label {
        padding-left: 0.75rem;
    }
    .p-dropdown-trigger {
        background-color: #e4e4e4;
        border-left: 1px solid #ced4da;
        color: #FFFFFF;
        &:hover {
            background-color: $primary !important;
            border-color: $primary !important;
            color: #FFFFFF;
        }
    }
    p-dropdownitem {
        li {
            // max-width: calc(100vw - 50px);
            white-space: normal;
        }
    }
    &.border-danger {
        border-color: $danger !important;
    }
}

.p-autocomplete {
    width: 100% !important;
    .p-autocomplete-input {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: inherit;
        padding: 0.375rem 0.75rem;
        // height: calc(2.25rem + 2px);
        &:hover {
            border-color: #ced4da !important;
        }
        &:focus {
            border-color: #9acffa !important;
            outline: 0 !important;
            box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25) !important;
        }
    }

    // &.p-autocomplete-multiple {
        .p-inputtext {
            width: 100%;
            border: 1px solid #ced4da;
            border-radius: 0.25rem 0 0 0.25rem;
            .p-autocomplete-token {
                background-color: $primary !important;
                .p-icon-wrapper {
                    margin-bottom: 4px;
                    vertical-align: bottom;
                }
            }
        }
        .p-autocomplete-dropdown {
            background-color: #e4e4e4;
            border: 1px solid #ced4da;
            border-left-width: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            &:hover {
                background-color: $primary !important;
                border-color: $primary !important;
                color: #FFFFFF;
            }
        }
        ul {
            margin-bottom: 0;
        }
    // }
    .p-autocomplete-multiple-container {
        gap: 0.2rem;

        .p-autocomplete-token {
            border-radius: 5px;
            align-items: end;
            color: #ffffff;
        }
    }
    &.ui-autocomplete-dd {
        .ui-autocomplete-dropdown {
            &.ui-corner-all {
                margin-left: 0;
                width: 33px;
                height: 35px;
                background-color: #e4e4e4;
                border-color: #e4e4e4;
            }
        }
        .ui-autocomplete-multiple-container {
            margin-right: -33px;
            border-radius: 0.25rem !important;
            padding-top: 0 !important;
            min-height: 35px;
            padding-right: 36px !important;
            .ui-autocomplete-token {
                margin-top: 0.2145em !important;
            }
        }
    }
    li.p-autocomplete-empty-message {
        padding: .25rem 1rem;
        color: #a7a7a7;
    }
}
.p-datepicker {
    padding: 0 !important;
    width: 100%;
    .ui-datepicker-group {
        padding: .75rem !important;
        .ui-datepicker-header {
            background-color: #4CAF50;
            padding: 7px 0;
            color: #FFFFFF;
            border-radius: .25rem;
            .ui-datepicker-prev, .ui-datepicker-next {
                color: #FFFFFF;
                top: 2px;
            }
        }
    }
    .p-datepicker-input {
        width: 100%;
    }
}
.p-disabled, .p-component:disabled,
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: .5 !important;
}
.summer-place {
    border-bottom: 2px dotted $purple;
}
@media print {
    .summer-place {
        border-bottom: none;
    }
}
.input-file {
    padding: 0;
    height: auto;
    &::-webkit-file-upload-button {
        visibility: hidden;
    }
    &::before {
        content: 'Escolher Arquivo';
        display: inline-block;
        background: #e4e4e4;
        border-right: 1px solid #999;
        border-radius: 0;
        padding: 7px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-size: 14px;
        height: calc(2.25rem + 0px);
    }
}

.tox-tinymce {
    border-radius: 5px !important;
    border: 1px solid #ced4da !important;

    .tox-menubar:not(:empty) {
        border-bottom: 2px solid #ced4da !important;
    }

    .tox-toolbar__group {
        border-right: 1px solid #ced4da !important;
    }
    .tox-toolbar__group:last-child {
        border-right: 0 !important;
    }
}
