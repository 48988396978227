footer{
    background-color: #e8e8e8;
    padding: 2px;
    margin-top: 30px;
    bottom: 0;
    position: absolute;
    width: 100%;
    p {
        color: #b9b9b9;
        font-size: .75rem;
        margin: 0;
        padding-top: 4px;
        a {
            color: #b3b3b3;
            &:hover {
                color: #666666;
                text-decoration: none;
            }
        }
    }
    .list-inline {
        margin: 0;
        a {
            color: #b3b3b3;
            font-size: .75rem;

            &:hover {
                color: #666666;
                text-decoration: none;
            }
        }
    }
}