@media Screen {
    html {
        body {
            &.page-report {
                margin-bottom: 0;
                table.page-report-table {
                    width: 100%;
                    .page-report-signature {
                        background-color: #FFFFFF;
                        padding-bottom: 40px;
                    }
                    & tfoot {
                        display: none;
                    }
                }
            }
            .page-report-header {
                background-color: #ffffff;
                display: block !important;
                padding: 15px 50px;
                .date {
                    word-break: break-all;
                }
            }
            .page-report-header {
                .mb-5 {
                    margin-bottom: 0 !important;
                }
            }
            .page-report-footer {
                background-color: #ffffff;
                display: block !important;
                padding: 15px 50px;
            }
            @include media-breakpoint-down(xs) {
                &.page-report {
                    background-color: #ffffff;
                }
                .page-report-header {
                    padding: 1rem;
                    .date {
                        font-size: 12px;
                    }
                }
                .page-report-footer {
                    padding: 1rem;
                }
                .box {
                    box-shadow: none;
                    border-radius: 0 !important;
                }
                .signature {
                    &.row {
                        margin: 0;
                    }
                }
            }
        }
    }
}
html {
    body {
        .title-report {
            background-color: #daf3db;
            border: 2px solid #4CAF50;
            color: #4CAF50;
            border-top-width: 0;
            border-right-width: 0;
            border-radius: 0 .25rem 0 .25rem;
            box-shadow: -2px 2px 0px #daf3db;
            &.title-report-primary {
                background-color: #d2ebff;
                border-color: #2196f3;
                color: #2196f3;
                box-shadow: -2px 2px 0px #d2ebff;
            }
        }
        td, th {
            &.title-report {
                border-top-width: 2px;
                border-right-width: 2px;
                box-shadow: none !important;
            }
        }
        .page-report-header {
            .logo {
                padding-bottom: 4px;
            }
            .date {
                line-height: 1;
                text-align: right;
                padding-top: 14px;
                small {
                    line-height: 3;
                }
            }
            //#page-number:after {
            //    counter-increment: page;
            //    content: "Pág: " counter(page);
            //}
        }
    }
}