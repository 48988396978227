.drag-drop-list {
    /* width: 500px; */
    /* max-width: 100%; */
    border: 1px solid rgba(0, 0, 0, 0.125);
    /* min-height: 60px; */
    /* display: block; */
    background: #FFFFFF;
    border-radius: .25rem;
    /* overflow: hidden; */
}

.drag-drop-box {
    padding: 10px 10px 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    /* color: rgba(0, 0, 0, 0.87); */
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: space-between; */
    /* box-sizing: border-box; */
    background: #FFFFFF;
    /* font-size: 14px; */
    border-radius: .25rem .25rem 0 0;
}

.drag-drop-box span {
    cursor: move;
    display: block;
    text-align: center;
    margin-bottom: 16px;
}

.cdk-drag-placeholder {
    opacity: 1;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: .25rem;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-box:first-child {
    border-radius: .25rem;
}

.drag-drop-box:last-child {
    border: none;
    border-radius: .25rem;
}

.drag-drop-list.cdk-drop-list-dragging .drag-drop-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
