.breadcrumb-line {
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    color: #333;
    .breadcrumb {
        margin-bottom: 0;
        background-color: transparent;
        border-radius: 0;
        color: #333333;
        font-size: .8125rem;
        padding-left: 0;
        .client {
            font-size: 1rem;
            margin-top: -3px;
        }
        .breadcrumb-item {
            a {
                color: #333333;
            }
        }
    }
    .nav-help {
        display: flex;
        flex-wrap: wrap;
        padding: 0.55rem 1rem 0.75rem;
        list-style: none;
        margin-bottom: 0;
        .nav-help-item {
            .nav-help-link {
                font-size: .8125rem;
                color: #333333;
            }
        }
    }
}