body {
    .ui-tabview {
        padding: 0 !important;
        &.ui-tabview-top {
            .ui-tabview-nav {
                li {
                    border: 0 !important;
                    background-color: #ced4da !important;
                    a {
                        color: $secondary !important;
                    }
                    &.ui-state-active {
                        background-color: $gray-super-light !important;
                        a {
                            color: $gray-900 !important;
                        }
                    }
                }
            }
        }
        .ui-tabview-panels {
            border: 0 !important;
            padding: 0 !important;
            background-color: transparent !important;
        }
    }
}
