/*
.masonry {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: minmax(180px, auto);
    grid-auto-flow: dense;
    .masonry-item {
        &:nth-child(odd) {

        }
        &.masonry-span-2 {
            grid-column-end: span 2;
            grid-row-end: span 2;
        }
        &.masonry-span-3 {
            grid-column-end: span 3;
            grid-row-end: span 4;
        }
    }
}
*/
