.btn {
    &.btn-bars {
        padding: 0;
        background-color: transparent;
        i {
            margin: 0 !important;
        }
    }
}

.btn-facebook {
    background: #3C599B;
    border-color: #3C599B;
}

.btn-google {
    background: #E04A39;
    border-color: #E04A39;
}

.btn-purple {
    background: $purple;
    border-color: $purple;
    color: #ffffff;
}

.btn-purple:hover {
    background: $purple-light-2;
    border-color: $purple;
}

.btn-purple-light {
    background: #b681f3;
    border-color: #b681f3;
    color: $purple;
}

.btn-purple-light:hover {
    background: $purple-light-2;
    border-color: $purple-light;
}

.btn-info-light {
    background: $info-light;
    border-color: $info-light;
    color: #0090a3;
}

.btn-info-light:hover {
    background: $purple-light-2;
    border-color: $info-light;
}

.btn-ia {
    background: $purple-light-2;
    border-color: $purple;
}

.btn-ia:hover {
    background: $purple;
    border-color: $purple;
    color: #ffffff;
}

.btn-ia img {
    margin-top: -4px;
}

.btn-ia:hover img {
    filter: brightness(100);
}

.btn-ia:disabled {
    background: transparent;
    border-color: $gray-light_3;
    color: #333333;
}

.btn-ia:disabled img {
    filter: brightness(0);
    opacity: 0.6;
}

.btn-submit {
    .spin {
        display: none;
    }
    .text {
        display: block;
    }
    &.submitting {
        .spin {
            display: block;
        }
        .text {
            display: none;
        }
    }
}

.btn-gray-light {
    color: #848484;
    background-color: #f8f9fa;
    border-color: #f1f0f0;
}

.btn-gray {
    color: $gray-900;
    background-color: #d1d1d1;
    border-color: #d1d1d1;
}

.btn-gray:hover {
    background-color: #ececec;
}

.input-clear {
    position: relative;
    .input-clear-icon {
        display: none;
        position: absolute;
        right: 15px;
        top: 19px;
        cursor: pointer;
        margin-left: 0 !important;
        margin-right: 0;
        color: $gray-400;
        &:hover {
            color: $gray-900;
        }
        @include media-breakpoint-up(md) {
            top: 11px;
            right: 8px;
        }
    }
    &.has-clear {
        .input-clear-icon {
            display: inline-block;
        }
    }
    &:not(.no-dropdown) {
        .form-control {
            @include media-breakpoint-up(md) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    .form-control {
        padding-right: 1.5rem;
    }
}
.btn-icon-social {
    background-color: #AEAEAC;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 3px 0px 0;
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
    margin-right: 5px;
}
.btn-icon-social-active-facebook {
    background-color: #3b5998;
}
.btn-icon-social-active-facebook:hover {
    background-color: #4c70ba;
}
.btn-icon-social-active-messenger {
    background-color: #007fff;
}
.btn-icon-social-active-messenger:hover {
    background-color: #005eff;
}
.btn-icon-social-active-google-plus {
    background-color: #dd4b39;
}
.btn-icon-social-active-google-plus:hover {
    background-color: #e47365;
}
.btn-icon-social-active-instagram {
    background-color: #3f729b;
}
.btn-icon-social-active-instagram:hover {
    background-color: #548cb9;
}
.btn-icon-social-active-linkedin {
    background-color: #0976b4;
}
.btn-icon-social-active-linkedin:hover {
    background-color: #0b96e5;
}
.btn-icon-social-active-twitter {
    background-color: #55acee;
}
.btn-icon-social-active-twitter:hover {
    background-color: #83c3f3;
}
.btn-icon-social-active-youtube {
    background-color: #e52d27;
}
.btn-icon-social-active-youtube:hover {
    background-color: #ea5955;
}
.btn-icon-social-active-whatsapp {
    background-color: #2FB343;
}
.btn-icon-social-active-whatsapp:hover {
    background-color: #6bd079;
}
.btn-icon-social-active-email {
    background-color: #da9a06;
}
.btn-icon-social-active-email:hover {
    background-color: #dab811;
}
.btn-icon-social-active-site {
    background-color: #E74F4D;
}
.btn-icon-social-active-site:hover {
    background-color: #e77067;
}
.btn-icon-social-active-blog {
    background-color: #DA7440;
}
.btn-icon-social-active-blog:hover {
    background-color: #da9050;
}



.radio-buttons {
    margin: 0;
    padding: 0;
    &:after {
        content: "";
        clear: both;
    }
    .radio-button {
        box-sizing: border-box;
        float: left;
        height: 36px;
        position: relative;
        width: 80px;
        margin: 0 14px;
        label {
            background-color: $gray-light_3;
            bottom: 0px;
            cursor: pointer;
            display: inline;
            font-size: 16px;
            left: 0px;
            position: absolute;
            right: 0px;
            top: 0px;
            white-space: nowrap;
            color: #FFFFFF;
            font-weight: bold;
            padding-top: 5px;
            margin: 0;
            border-radius: .25rem;
            &:hover {
                background-color: $gray-light_4;
            }
        }
        input {
            &:disabled {
                opacity: 0;
                &+ label {
                    opacity: .7;
                }
            }
        }
        &.radio-button-success {
            input {
                &:checked {
                    &+ label {
                        background-color: $success;
                        &:hover {
                            background-color: #008154;
                        }
                    }
                }
            }
        }
        &.radio-button-danger {
            input {
                &:checked {
                    &+ label {
                        background-color: $danger;
                        &:hover {
                            background-color: #f22112;
                        }
                    }
                }
            }
        }
        &.radio-button-info {
            input {
                &:checked {
                    &+ label {
                        background-color: $info;
                        &:hover {
                            background-color: #009aae;
                        }
                    }
                }
            }
        }
        &.radio-button-primary {
            input {
                &:checked {
                    &+ label {
                        background-color: $primary;
                        &:hover {
                            background-color: #0c83e2;
                        }
                    }
                }
            }
        }
        &.radio-button-warning {
            input {
                &:checked {
                    &+ label {
                        background-color: $warning;
                        &:hover {
                            background-color: #ff9900;
                        }
                    }
                }
            }
        }
    }
}
