.app-body {
  &.p-page {
    p {
      text-align: justify;
    }
  }

  &.p-termos-de-uso {

    .page-content {

      ol:not([type="a"]) {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
      }

      ol:not([type="a"]) > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
        font-weight: bold;
        padding-left: 8px;
        padding-top: 6px;
        text-align: justify;
        text-transform: uppercase;
      }

      ol:not([type="a"]) > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
        font-weight: bold;
      }

      ol:not([type="a"]) > li ol:not([type="a"]) > li {
        margin: 0;
        font-weight: normal;
        background: none;
        padding-left: 0;
        padding-right: 8px;
        text-transform: none;
      }

      ol:not([type="a"]) > li > ol:not([type="a"]) > li:before {
        content: counters(item, ".") " ";
        font-weight: normal;
      }

      ol[type="a"] li {
        padding-top: 6px;
      }

      ol[type="a"] li:last-child {
        padding-bottom: 6px;
      }
    }

  }
}
